import { formatEmail, formatPhoneNumber, constants } from "../utils";

function ContactBanner() {
    const formattedNumberOne = formatPhoneNumber(constants.SDR_GLOBAL_CONTACT_1)
    const formattedNumberTwo = formatPhoneNumber(constants.SDR_GLOBAL_CONTACT_2)
    const formattedEmail = formatEmail(constants.SDR_CONNECT_COMMUNITY_EMAIL) 
    return (
        <div className='shopify-section mount-announcement'>
            <span className='announcement'>
                <span className='announcement__text'>
                    Enquiry: <a href={formattedNumberOne}>{constants.SDR_GLOBAL_CONTACT_1}</a> | <a href={formattedNumberTwo}>{constants.SDR_GLOBAL_CONTACT_2}</a> | <a href={formattedEmail}>{constants.SDR_CONNECT_COMMUNITY_EMAIL}</a>
                </span>
            </span>
        </div>
    );
}

export default ContactBanner;