import { constants } from "../utils";
const sdrConnectLogo = require('../images/sdr-connect-logo.png')

function SDRHeaderDisplay() {
    return (
        <div id="shopify-section-header" className="shopify-section">
            <header className="header-holder">
                <div className="header wrapper">
                    <div id="logo" className="logo">
                        <a className="logo-img" title={constants.SDR_TITLE} tabIndex="3" href={constants.SDR_GLOBAL_MAIN} target="_blank">
                            <img 
                                className="hp-logo-black" 
                                src={sdrConnectLogo} 
                                alt={constants.SDR_CONNECT_LOGO_ALT_TEXT}
                                width="400" 
                                height="94"
                            />
                        </a>
                    </div>
                </div>
            </header>
        </div>
    );
  }


  export default SDRHeaderDisplay;