
import React, { useState, useEffect } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input'

import { constants, ACCEPTANCE, config} from "../utils";
import { Fragment } from 'react';

function GenderOptions() {
    const [genderList, setGenderList] = useState([]);
    useEffect(() => {

        const apiUrl = `${config.SDR_CONNECT_BACKEND_API}/v1/options/gender`
        fetch(apiUrl)
            .then(res => res.json())
            .then(res => setGenderList(res.options))
            .catch(err => console.error(err))

    }, [])

    return genderList.map((gender) => {
        return (
            <>
                <option value={gender}>{gender}</option>
            </>
        )
    })
}

function LanguageOptions() {

    const [languageList, setLanguageList] = useState([]);
    useEffect(() => {

        const apiUrl = `${config.SDR_CONNECT_BACKEND_API}/v1/options/language`
        fetch(apiUrl)
            .then(res => res.json())
            .then(res => setLanguageList(res.options))
            .catch(err => console.error(err))

    }, [])

    return languageList.map((language) => {
        return (
            <>
                <option value={language}>{language}</option>
            </>
        )
    })
}

function CategoryOptions() {

    const [categoryList, setCategoryList] = useState([]);
    useEffect(() => {
        const apiUrl = `${config.SDR_CONNECT_BACKEND_API}/v1/options/category`
        fetch(apiUrl)
            .then(res => res.json())
            .then(res => setCategoryList(res.options))
            .catch(err => console.error(err))

    }, [])


    return categoryList.map((category) => {
        return (
            <>
                <option value={category}>{category}</option>
            </>
        )
    })
}

function MemberTypeOptions() {

    const [memberTypeList, setMemberTypeList] = useState([]);
    useEffect(() => {
        const apiUrl = `${config.SDR_CONNECT_BACKEND_API}/v1/options/member_type`
        fetch(apiUrl)
            .then(res => res.json())
            .then(res => setMemberTypeList(res.options))
            .catch(err => console.error(err))

    }, [])


    return memberTypeList.map((memberType) => {
        return (
            <>
                <option value={memberType}>{memberType}</option>
            </>
        )
    })
}


function AcceptanceOptions() {
    return (
        <>
            <option value={ACCEPTANCE.YES}>{ACCEPTANCE.YES}</option>
            <option value={ACCEPTANCE.NO} selected>{ACCEPTANCE.NO}</option>
        </>
    )
}

function RegistrationForm() {
    const [formData, setFormData] = useState(
        { 
            full_name: "",
            email: "", 
            contact_number: "",
            birth_date: "",
            gender: "",
            pincode: "",
            language: "",
            category: "",
            insta_handle: "",
            youtube_channel: "",
            member_type: ""
        }
    );

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedInstaValue, setSelectedInstaValue] = useState(ACCEPTANCE.NO);
    const [showInstaDiv, setShowInstaDiv] = useState(false);
    const [selectedYoutubeValue, setSelectedYoutubeValue] = useState(ACCEPTANCE.NO);
    const [showYoutubeDiv, setShowYoutubeDiv] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let [validationPassed, errMessage] = customFormValidation()

        if (validationPassed) {
            const response = await fetch(`${config.SDR_CONNECT_BACKEND_API}/v1/community/join`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setSuccessMessage('Thankyou for the interest in Seller Drive Community. We will get back to you shortly');
                setFormData({ 
                    full_name: "",
                    email: "", 
                    contact_number: "",
                    birth_date: "",
                    gender: "",
                    pincode: "",
                    language: "",
                    category: "",
                    insta_handle: "",
                    youtube_channel: "",
                    member_type: ""
                });
                setSelectedInstaValue(ACCEPTANCE.NO)
                setShowInstaDiv(false)
                setSelectedYoutubeValue(ACCEPTANCE.NO)
                setShowYoutubeDiv(false)
                setIsFormValid(false)
            } else {
                setErrorMessage('⚠️ An internal error occurred, please try in some time!')
            }      
        } else {
            setErrorMessage(errMessage)
        }

    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // let value = Array.from(e.target.selectedOptions, option => option.value);
        setFormData({ ...formData, [name]: value });
    }

    const customFormValidation = () => {
        let errMessage = ""

        const form = document.getElementById("contact_form")

        // length of pin code should be 6
        const validPin = formData.pincode.length === 6 ? true : false
        console.log(`valid pincode ? ${validPin}`)
        if (!validPin) {
            errMessage += "⛔️ invalid pincode, it must be 6 digit number eg 110055\n"
        }

        // mobile number should be a valid number
        const validContactNumber = isValidPhoneNumber(formData.contact_number)
        console.log(`valid contact number ? ${validContactNumber}`)
        if (!validContactNumber) {
            errMessage += "⛔️ invalid mobile number, it must include country code eg +918888888888\n"
        }

        // at least one of the social media profile should be shared
        const socialMediaProvided = ((selectedInstaValue === ACCEPTANCE.YES) || (selectedYoutubeValue === ACCEPTANCE.YES)) ? true : false
        console.log(`social media requirement met ? ${socialMediaProvided}`)
        if (!socialMediaProvided) {
            errMessage += "⛔️ you must provide at least one of social media handle"
        }

        if (validPin && validContactNumber && socialMediaProvided) {
            return [true, '']
        } else {
            return [false, errMessage]
        }

    }

    const handleSocialMediaDropdownChange = (event) => {
        const { name, value } = event.target;
        if (name === constants.SDR_REGISTRATION.instaDropdown.id) {
            setSelectedInstaValue(value);
        } else if (name === constants.SDR_REGISTRATION.youtubeDropdown.id) {
            setSelectedYoutubeValue(value)
        }
    }

    useEffect(() => {
        setShowInstaDiv(selectedInstaValue === ACCEPTANCE.YES);
    }, [selectedInstaValue]);

    useEffect(() => {
        setShowYoutubeDiv(selectedYoutubeValue === ACCEPTANCE.YES);
    }, [selectedYoutubeValue])


    useEffect(() => {
        if (successMessage.trim() !== '') {
            const timer = setTimeout(() => {
                setSuccessMessage('')
            }, 10000);

            return () => clearTimeout(timer)
        }

        if (errorMessage.trim() !== '') {
            const timer = setTimeout(() => {
                setErrorMessage('')
            }, 10000);

            return () => clearTimeout(timer)
        }
    }, [successMessage, errorMessage])


    return (
       <>
            {successMessage && <p>{successMessage}</p>}
            {errorMessage && <p>{errorMessage}</p>}
            <form id="contact_form" className="contact-form" onSubmit={handleSubmit}>
                
                <div className='simple-grid'>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.memberType.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.memberType.title}
                        </label>

                        <select 
                            name={constants.SDR_REGISTRATION.memberType.id} 
                            id={constants.SDR_REGISTRATION.memberType.id} 
                            className="input-full"
                            value={formData.member_type}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" selected>{constants.SDR_REGISTRATION.memberType.placeholder}</option>
                            <MemberTypeOptions />
                        </select> 
                    </div>
                    
                    <div className="one-half"></div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.fullName.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.fullName.title}
                        </label>
                        <input 
                            type="text" 
                            id={constants.SDR_REGISTRATION.fullName.id} 
                            className="input-full" name={constants.SDR_REGISTRATION.fullName.id} 
                            required
                            placeholder={constants.SDR_REGISTRATION.fullName.placeholder}
                            value={formData.full_name}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.email.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.email.title}
                        </label>
                        <input 
                            type="email" 
                            id={constants.SDR_REGISTRATION.email.id} 
                            className="input-full" 
                            name={constants.SDR_REGISTRATION.email.id} 
                            required
                            placeholder={constants.SDR_REGISTRATION.email.placeholder}
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.dateOfBirth.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.dateOfBirth.title}
                        </label>
                        <input 
                            type="date" 
                            id={constants.SDR_REGISTRATION.dateOfBirth.id} 
                            className="input-full" 
                            name={constants.SDR_REGISTRATION.dateOfBirth.id} 
                            required
                            placeholder={constants.SDR_REGISTRATION.dateOfBirth.placeholder}
                            value={formData.birth_date}
                            onChange={handleInputChange}
                            
                        />
                    </div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.gender.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.gender.title}
                        </label>

                        <select 
                            name={constants.SDR_REGISTRATION.gender.id} 
                            id={constants.SDR_REGISTRATION.gender.id} 
                            className="input-full"
                            value={formData.gender}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" selected>{constants.SDR_REGISTRATION.gender.placeholder}</option>
                            <GenderOptions/>
                        </select> 
                    </div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.mobile.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.mobile.title}
                        </label>
                        <input 
                            type="text" 
                            id={constants.SDR_REGISTRATION.mobile.id} 
                            className="input-full" 
                            name={constants.SDR_REGISTRATION.mobile.id} 
                            required
                            placeholder={constants.SDR_REGISTRATION.mobile.placeholder}
                            value={formData.contact_number}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.pinCode.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.pinCode.title}
                        </label>
                        <input 
                            type="number" 
                            id={constants.SDR_REGISTRATION.pinCode.id} 
                            className="input-full" 
                            name={constants.SDR_REGISTRATION.pinCode.id} 
                            placeholder={constants.SDR_REGISTRATION.pinCode.placeholder}
                            value={formData.pincode}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.language.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.language.title}
                        </label>

                        <select 
                            name={constants.SDR_REGISTRATION.language.id} 
                            id={constants.SDR_REGISTRATION.language.id} 
                            className="input-full"
                            value={formData.language}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" selected>{constants.SDR_REGISTRATION.language.placeholder}</option>
                            <LanguageOptions />
                        </select> 
                    </div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.category.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.category.title}
                        </label>

                        <select 
                            name={constants.SDR_REGISTRATION.category.id} 
                            id={constants.SDR_REGISTRATION.category.id} 
                            className="input-full"
                            value={formData.category}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" selected>{constants.SDR_REGISTRATION.category.placeholder}</option>
                            <CategoryOptions />
                        </select> 
                    </div>

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.instaDropdown.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.instaDropdown.title}
                        </label>

                        <select 
                            name={constants.SDR_REGISTRATION.instaDropdown.id} 
                            id={constants.SDR_REGISTRATION.instaDropdown.id} 
                            className="input-full"
                            value={selectedInstaValue}
                            onChange={handleSocialMediaDropdownChange}
                            required
                        >
                            <AcceptanceOptions />
                        </select> 
                    </div>

                    { showInstaDiv && 
                        <div className="one-half">
                            <label htmlFor={constants.SDR_REGISTRATION.insta.id} className="text-size--small">
                                {constants.SDR_REGISTRATION.insta.title}
                            </label>
                            <input 
                                type="text" 
                                id={constants.SDR_REGISTRATION.insta.id} 
                                className="input-full" 
                                name={constants.SDR_REGISTRATION.insta.id} 
                                placeholder={constants.SDR_REGISTRATION.insta.placeholder}
                                value={formData.insta_handle}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    }

                    <div className="one-half">
                        <label htmlFor={constants.SDR_REGISTRATION.youtubeDropdown.id} className="text-size--small">
                            {constants.SDR_REGISTRATION.youtubeDropdown.title}
                        </label>

                        <select 
                            name={constants.SDR_REGISTRATION.youtubeDropdown.id} 
                            id={constants.SDR_REGISTRATION.youtubeDropdown.id} 
                            className="input-full"
                            value={selectedYoutubeValue}
                            onChange={handleSocialMediaDropdownChange}
                            required
                        >
                            <AcceptanceOptions />
                        </select> 
                    </div>

                    { showYoutubeDiv && 
                        <div className="one-half">
                            <label htmlFor={constants.SDR_REGISTRATION.youtube.id} className="text-size--small">
                                {constants.SDR_REGISTRATION.youtube.title}
                            </label>
                            <input 
                                type="text" 
                                id={constants.SDR_REGISTRATION.youtube.id} 
                                className="input-full" 
                                name={constants.SDR_REGISTRATION.youtube.id} 
                                placeholder={constants.SDR_REGISTRATION.youtube.placeholder}
                                value={formData.youtube_channel}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    }
                    <div className="one-whole">
                        <button type="submit" className="button button--solid button--wide button--align" aria-label="Submit message"><svg aria-hidden="true" focusable="false" role="presentation" width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0.957243 14.6996L31.9091 0.965088L26.1462 28.6264L18.7847 24.5487L14.7692 30.635L14.3489 22.0916L28.6547 4.2271L11.2803 20.3894L0.957243 14.6996Z" fill="white"></path></svg>
                        </button>
                    </div>
                </div>

            </form>
       </>
    )
}

function SDRRegistrationForm() {
    return (
        <div className="hp-statistics">
            < RegistrationForm />
        </div>
    );
}

export default SDRRegistrationForm;