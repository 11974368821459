import './style/main.css'
import './style/theme.css'

import ContactBanner from "./sections/announcement";
import SDRHeaderDisplay from "./sections/header";
import FooterDisplay from "./sections/footer";
import PageMainContent from './sections/main'; 


function App() {
  return (
    <div >
      <ContactBanner />
      <SDRHeaderDisplay />
      <PageMainContent />
      <FooterDisplay />
    </div>
  );
}

export default App;
