import { constants } from "../utils";

import SDRRegistrationForm from "./registration";

function MainBodyBanner() {
    const sdcBanner = require('../images/sdc-banner-min.png')
    const sdcMobileBanner = require('../images/sdc-banner-mobile-min.png')
    return (
        <div className="sdc-wide-banner">
            <img 
                className="sdc-wide-banner-desktop" 
                src={sdcBanner} 
                alt={constants.SDR_CONNECT_LOGO_ALT_TEXT}
                width="100%" 
                height="">
            </img>
            <img 
                className="sdc-wide-banner-mobile" 
                src={sdcMobileBanner} 
                alt={constants.SDR_CONNECT_LOGO_ALT_TEXT} 
                width="100%" 
                height="">
            </img>
        </div>
    );
}

function MainBodyStatistics() {
    return (
        <div className="hp-statistics">
            <h2>our numbers</h2>

            <div className="clr"></div>

            <div className="hp-statistics-div border-right">
                <h3>15K+</h3>
                <h4>Merchants<br/>Served So Far</h4>
            </div>

            <div className="hp-statistics-div border-left">
                <h3>13K+</h3>
                <h4>Amazon <br/>Merchants Served</h4>
            </div> 

            <div className="hp-statistics-div border-right">
                <h3>8K+</h3>
                <h4>Walmart <br/>Merchants Served</h4>
            </div>

            <div className="hp-statistics-div border-left">
                <h3>70+</h3>
                <h4>Skilled <br/>Team</h4>
            </div>

            <div className="clr"></div>

            <div className="hp-statistics-div border-right">
                <h3>2.3K+</h3>
                <h4>Facebook <br/>Followers</h4>
            </div> 

            <div className="hp-statistics-div border-left">
                <h3>340+</h3>
                <h4>Instagram<br/> Followers</h4>
            </div>

            <div className="hp-statistics-div border-right">
                <h3>180+</h3>
                <h4>Youtube<br/>Subscribers</h4>
            </div>

            <div className="hp-statistics-div border-right">
                <h3>100+</h3>
                <h4>Google<br/> Reviews</h4>
            </div>
        </div>
    );
}


function MainBodyDataExploratorySection() {
    return (
        <div id="shopify-section-1620411854e404eaf1" className="shopify-section section-w-margin section-w-mobile-padding">
            <div className="ten-tenths lap--full-width push-left-one-tenth lap--push-left-none">
                <div className="hp-heading1">
                    <div className="container numbers-left">
                        <h3>{constants.SDR_STATISTICS.header}</h3>
                        <p>{constants.SDR_STATISTICS.description}</p>
                    </div>

                    <MainBodyStatistics />

                    <div className="clr"></div>
                </div>
            </div>
        </div>
    );
}


function MainBodyCollaboratorSection() {
    return (
        <div id="shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0" className="shopify-section section-w-margin section-w-mobile-padding">
            <div className="ten-tenths lap--full-width push-left-one-tenth lap--push-left-none">
                <div className="hp-heading1">  
                    <div className="section-header">
                        <span className="section-header__title h4">{constants.SDR_COLLABS.header}</span>
                    </div>
                        
                    <div className="rich-text__content rte">
                        <p>{constants.SDR_COLLABS.description}</p>
                    </div>
                        
                    <SDRRegistrationForm />

                    <div className="clr"></div> 
                </div>
            </div>
        </div>
    );
}
function PageMainContent() {
    
    return (
        <>
            <main id="main" className="page-content">
                <MainBodyBanner />
                <MainBodyDataExploratorySection />
                <MainBodyCollaboratorSection />
          </main>
        </>
      );
  }

export default PageMainContent;