import { Fragment } from "react";
function formatEmail(email) {
    return `mailto:${email.replaceAll(' ', '')}`
}

function formatPhoneNumber(number) {
    return `tel:${number.replaceAll(' ', '')}`
}

const marquees = [
    { altText: "Unico Organics", link: "https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-suddora-min.png" },
    { altText: 'Suddora', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-suddora-min.png?v=1672304206' },
    { altText: 'My Golfing Store', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-my-golfing-store-min.png?v=1672304206' },
    { altText: 'Trigon Sports', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-trigon-sports-min.png?v=1672304205' },
    { altText: 'Bio Shield Tech', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-bioshield-min.png?v=1679568009' },
    { altText: 'Ultimate Nutrition', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-ultimate-nutrition-min.png?v=1672304206' },
    { altText: 'Simple Organics', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-simple-organics-min.png?v=1672304206' },
    { altText: 'Just Messin', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-just-messin-min.png?v=1672304206' },
    { altText: 'Signatures', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-signatures-min.png?v=1672304206' },
    { altText: 'MIKO', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-MIKO-min.png?v=1672304206' },
    { altText: 'Nothing Fits But', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-nothing-fits-but-min.png?v=1672304206' },
    { altText: 'Santana', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-santana-min.png?v=1672304206' },
    { altText: 'Funny Guy Mugs', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-funny-guy-mugs-min.png?v=1672304206' },
    { altText: 'Shoe Land', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-shoe-land-min.png?v=1672304206' },
    { altText: 'Gevity Vitamins', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-gevity-vitamins-min.png?v=1672304206' },
    { altText: 'Nice Gummies', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-nice-gummies-min.png?v=1672304205' },
    { altText: 'Vast Exact', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-vast-exact-min.png?v=1672304206' },
    { altText: 'Med Massager', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-med-massager-min.png?v=1672304206' },
    { altText: 'Pure By Nature', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-pure-by-nature-min.png?v=1672304207' },
    { altText: 'Anthony Veer', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-anthony-veer-min.png?v=1672304206' },
    { altText: 'Ancient Bliss', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-ancient-bliss-min.png?v=1672304206' },
    { altText: 'Pretty Seamless', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-pretty-seamless-min.png?v=1672304206' },
    { altText: 'Under Summer', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-under-summer-min.png?v=1672304206' }
]

const footerItems = {
    legalTerms: [
        { name: "Disclaimer", link: "/pages/disclaimer" },
        { name: "Privacy Policy", link: "/pages/privacy-policy" },
        { name: "Refund Policy", link: "/pages/refund-policy" },
        { name: "Terms of Use", link: "/pages/terms-of-service" },
        { name: "Terms of Service", link: "/pages/terms-of-service" }
    ],
    contactDetails: {
        skype: { name: "SellerDriveGlobal", link: "#" },
        sdrContactPage: { name: "Contact Us", link: "/pages/contact-us" },
        sdrServicePackagePage: { name: "Complete Service Packs", link: "https://www.sellerdrive.com/collections/all" }
    },
    socialMedia: [],
    partners: [],
    textual: {
        aboutUs: "Seller Drive feels proud and overwhelmed by aiding sellers in every step of the way starting with Shopify store launch, complete account management on Amazon, global selling, enhancing visibility, advertisement, content, reporting, and list goes on. Our qualified team will be delighted to back you up making your business grow with boosted brand value leading to progression in sales and growth. Seller Drive will be your “Drive to Success”",
        copyRight: (<Fragment>Copyright © 2018-2023 <a href="/">Seller Drive</a><span className="powered-by-shopify" style={{ display: 'none' }}>. <a target="_blank" rel="noreferrer" href="https://www.shopify.com?utm_campaign=poweredby&amp;utm_medium=shopify&amp;utm_source=onlinestore">Powered by Shopify</a></span></Fragment>),
        drivenBy: (<Fragment>Everything on this store has been created with lots of effort and love! <br />It's Owned, Managed &amp; Funded by Proud Indian Family!</Fragment>)
    }
}

const statistics = {
    header: "who can connect",
    description: (<Fragment>We welcome every Seller Drive's Associate (be it Staff, Client, Vendor) or Influencer, who want to collaborate with us to promote wide range of products in the online space, and reach out to the relevant customers.<br /><br /></Fragment>)
}

const collabSection = {
    header: "Collaborate with us",
    description: (<Fragment><strong>If you are interested to join us on this exciting journey, fill in the below details.</strong></Fragment>)
}

const registration = {
    fullName: {
        id: "full_name",
        placeholder: "Your full name",
        title: "Name*"
    },
    email: {
        id: "email",
        placeholder: "Your email address",
        title: "Email*"
    },
    mobile: {
        id: "contact_number",
        placeholder: "Your mobile number with country code",
        title: "Contact Number*"
    },
    dateOfBirth: {
        id: "birth_date",
        placeholder: "Your date of birth (MM/DD/YYYY)",
        title: "Birthday*"
    },
    gender: {
        id: "gender",
        placeholder: "Select your gender",
        title: "Gender*"
    },
    pinCode: {
        id: "pincode",
        placeholder: "Enter your pincode",
        title: "Pin Code*"
    },
    language: {
        id: "language",
        placeholder: "Select your language",
        title: "Language*",
    },
    category: {
        id: "category",
        placeholder: "Select your category",
        title: "Category*"
    },
    instaDropdown: {
        id: "instaDropdown",
        title: "Do you have Instagram Profile?"
    },
    insta: {
        id: "insta_handle",
        placeholder: "Share your instgram user id",
        title: "Instagram Handle*"
    },
    youtubeDropdown: {
        id: "youtubeDropdown",
        title: "Do you have Youtube Channel?"
    },
    youtube: {
        id: "youtube_channel",
        placeholder: "Share your youtube channel name",
        title: "Youtube Handle*"
    },
    memberType: {
        id: "member_type",
        placeholder: "What defines you best?",
        title: "Member Type*"
    }
}

const config = Object.freeze({
    SDR_CONNECT_BACKEND_API: process.env.REACT_APP_SDR_CONNECT_BACKEND_API
})

const constants = Object.freeze({
    SDR_CONNECT_LOGO_ALT_TEXT: "Seller Drive Connect",
    SDR_TITLE: "Seller Drive Global",
    SDR_CONNECT_COMMUNITY_EMAIL: "community@sellerdrive.com",
    SDR_GLOBAL_CONTACT_1: '+1 828 782 3538',
    SDR_GLOBAL_CONTACT_2: '+1 828 330 3104',
    SDR_GLOBAL_MAIN: "https://www.sellerdrive.com/",
    SDR_MARQUEE_ITEMS: marquees,
    SDR_FOOTER_ITEMS: footerItems,
    SDR_STATISTICS: statistics,
    SDR_COLLABS: collabSection,
    SDR_REGISTRATION: registration
})


class ACCEPTANCE {
    static YES = "Yes";
    static NO = "No";
}


export {
    formatEmail,
    formatPhoneNumber,
    config,
    constants,
    ACCEPTANCE
}